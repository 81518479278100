.login-page {
  background: linear-gradient(126deg, #ff272f, #efa79b);
  background-size: 180% 180%;
  animation: gradient-animation 15s ease infinite;
}
* {
  font-family: "Montserrat", sans-serif !important;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.input-error-desc {
  color: red;
}
.input-error-desc:first-letter {
  text-transform: capitalize;
}
.table-input {
  border-radius: 5px;
  border-width: 1px !important;
  padding: 5px;
  margin: 5px auto;
  width: 75% !important;
}
.mark-table tr,
th {
  text-align: center !important;
  vertical-align: middle;
}
.mark-table input {
  text-align: center;
  text-transform: capitalize;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-Primary.MuiButton-sizeMedium.MuiButton-SizeMedium.MuiButton-root.MuiButton-Primary.MuiButton-sizeMedium.MuiButton-SizeMedium.tss-1fbujeu-MUIDataTableHeadCell-toolButton.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  margin: auto;
  font-weight: bold;
}
.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
  text-align: center;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  margin: auto !important;
}
.uppercase {
  text-transform: uppercase;
}
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.capitalize {
  text-transform: capitalize;
}
.application-view .heading-content {
  line-height: 0.25;
  text-align: center;
  font-size: 12px;
}
.application-view .application-details p {
  font-size: 14px;
  width: 100%;
}
.application-view .application-details table,
.application-view .application-details th,
.application-view .application-details tr {
  border-collapse: collapse;
  border: 1px solid grey;
}
.application-view .application-details td {
  padding: 8px 30px;
}

.application-view .application-details td .value {
  /* width: 50%; */
  font-weight: bold;
  padding-left: 10px;
  text-transform: capitalize;
  /* white-space: nowrap; */
}
.application-view .application-details td .label {
  /* font-weight: bold; */
  font-size: 13px;
  text-transform: capitalize;
}
.application-view .application-details td span {
  font-size: 13px;
}
.application-view .form-table td {
  width: 50%;
}
#mark-table-preview {
  font-size: 13px !important;
}
#mark-table-preview {
  font-size: 13px !important;
}

#mark-table-preview .custom--mark-width {
  width: 10%;
}
#mark-table-preview .custom-mark-width {
  width: 10%;
}
#mark-table-preview td {
  text-align: center;
}

.application-view {
  font-family: sans-serif;
  /* border: 1px solid black; */
}
.custom--subject-width {
  width: 40%;
}
@media print {
  .page-break {
    page-break-before: always;
    margin-top: 25px;
  }
}
.banner-card {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 250px; */
  color: #e63d24;
  margin-top: 10px;
  /* background-color: lightgray; */
  border-radius: 10px;
}
.team-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 370px;
  width: 270px !important;
  color: #e63d24;
  margin-top: 10px;
  background-color: lightgray;
  border-radius: 10px;
}
.banner-card img {
  border-radius: 10px;
}
.event-dimension {
  position: absolute;
  background: white;
  padding: 5px 15px;
  border-radius: 10px;
  top: 0px;
  right: 0px;
  font-weight: 600;
  border: 3px solid red;
}

.ticket-container .item {
  background: #e1e1e1;
  overflow: hidden;
  display: flex;
  margin: 10px;
  border-radius: 10px;
  height: 220px;
  position: relative;
  transition: transform 0.3s ease;
}
.ticket-container .item:hover {
  transform: scale(1.03) translate(5px, -5px);
}
.ticket-container .item-right,
.ticket-container .item-left {
  float: left;
  padding: 20px;
}
.ticket-container .item-right {
  padding: 10px 0px 10px 20px;
  margin-right: 20px;
  width: 25%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ticket-container .item-right .up-border,
.ticket-container .item-right .down-border {
  padding: 14px 15px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
}
.ticket-container .item-right .up-border {
  top: -13px;
  right: -35px;
}
.ticket-container .item-right .down-border {
  bottom: -10px;
  right: -35px;
}
.ticket-container .item-right .num {
  font-size: 15px;
  text-align: center;
  color: white;
  background-color: #006332;
  border-radius: 15px;
  padding: 8px 16px;
  font-family: cursive;
}
.ticket-container .item-right .day,
.ticket-container .item-left .event {
  color: #555;
  font-size: 15px;
  margin-bottom: 9px;
}
.ticket-container .item-right .day {
  text-align: center;
  font-size: 25px;
  white-space: nowrap;
  font-family: fantasy;
}
.ticket-container .item-left {
  padding: 20px;
  border-left: 3px dotted #999;
  width: 100%;
}
.ticket-container .item-left .title {
  color: #111;
  font-size: 25px;
  margin-bottom: 12px;
}
.ticket-container .item-left .sce {
  margin-top: 5px;
  display: block;
}
.ticket-container .item-left .sce .icon,
.ticket-container .item-left .sce p,
.ticket-container .item-left .loc .icon,
.ticket-container .item-left .loc p {
  float: left;
  word-spacing: 5px;
  letter-spacing: 1px;
  color: #888;
  margin-bottom: 10px;
}

.ticket-container .item-left .loc {
  display: block;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
.fix {
  clear: both;
}

.linethrough {
  text-decoration: line-through;
}
@media only screen and (max-width: 1150px) {
  .ticket-container .item {
    width: 100%;
    margin-right: 20px;
  }
  div.ticket-container {
    margin: 0 20px auto;
  }
}

.ticket-checkbox {
  position: absolute;
  right: 15px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  appearance: none;
  background-color: white;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
}

.ticket-checkbox:checked {
  border: 5px solid #e63d24;
}
.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f5f5f5;
  padding: 20px;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  color: #333;
  display: flex;
  max-height: 500px;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 100px 0px 100px;
}

.dropzone p {
  margin: 0;
  color: red;
  font-weight: bold;
}

/* GALARY SECTION */
.cy_gal_img {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.cy_gal_img img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -ms-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  transition: transform 0.5s;
}
.cy_gal_img:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.cy_gal_img > img {
  width: 100%;
}
.cy_gal_img .img_gal_ovrly {
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.cy_gal_img .gal_buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cy_gal_img .gal_buttons .fa {
  background: var(--theme-color);
  color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  font-size: 15px;
  margin: 0px 5px;
  text-align: center;
  text-decoration: none;
  width: 36px;
  height: 36px;
  opacity: 0;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  position: relative;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -ms-transition: -moz-transform 0.3s, opacity 0.3s;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
.cy_gal_img .gal_buttons .fa:hover {
  background-color: #ffffff;
  color: var(--theme-color);
}
.cy_gal_img:hover .img_gal_ovrly {
  opacity: 1;
}
.cy_gal_img:hover .gal_buttons .fa {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.cy_gal_img .gal_buttons .fa:focus {
  outline: none;
}

/* BF ADMIN PANEL */

.screen-heading {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
  font-size: 12px !important;
}
.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #e5232a !important;
}
.css-11yukd5-MuiTabs-indicator {
  background-color: #e5232a !important;
}
.location-text {
  font-weight: 500;
}
body {
  background-color: whitesmoke !important;
}

#order-summary h5 {
  /* color: gray !important;
  font-weight: 600;
  font-size: 16px;
  text-decoration: dotted;
  text-decoration-line: underline; */
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  margin: auto;
  margin-left: 8px;
  margin-bottom: 8px;
}
#order-summary td,
#order-summary th {
  background-color: #ffffff !important;
}
#order-summary h5::before {
  position: absolute;
  left: 16px;
  content: "";
  display: inline-block;
  width: 3px;
  height: 24px;
  background-color: red;
}
.order-summary tr td {
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0%;
  background-color: #ffffff !important;
}
#order-summary .card {
  background-color: #ffffff !important;
  border: 1px solid #e9e9e9;
  box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.06);
}
#order-summary .table-light {
  background-color: #ffffff !important;
}
.order-label {
  margin: 0;
  color: #1976d2;
  font-weight: 700;
  background: white;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  right: 4px;
  bottom: 4px;
}
.order-details-box .MuiTypography-subtitle1 {
  font-size: 12px;
}
.MuiTable-root
  .MuiTableBody-root
  .MuiTableCell-root
  .tss-1qtl85h-MUIDataTableBodyCell-root
  p {
  margin: auto;
}
.custom-textarea {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 1px solid lightgray;
  border-radius: 4px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 1rem 0 0 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 15px;
  width: calc(100% - 30px) !important;
}
.custom-textarea:focus-visible {
  outline: #0d6efd auto 1px;
}
@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 55px !important;
  }
}
.tss-1cdcmys-MUIDataTable-responsiveBase {
  min-height: 300px;
}
.order-split-icon {
  font-size: 17px;
  margin-right: 8px;
  color: #e5232a;
}
.tss-1qtl85h-MUIDataTableBodyCell-root,
.css-1ygcj2i-MuiTableCell-root {
  padding: 6px !important;
}
.css-19kzrtu {
  padding: 24px 0 !important;
}
@media (min-width: 600px) {
  .css-1r9jet7 {
    min-height: 45px !important;
  }
}
.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  font-size: 0.865rem !important;
}
.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
  font-size: 0.835rem !important;
}
.status-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: center;
}
.countItem {
  padding: 16px;
}
.top-selling-cakes tbody td {
  font-size: 13px;
  padding: 12px 24px;
}
.top-selling-cakes tbody tr:hover {
  scale: 1.05;
  font-weight: 600;
  background-color: rgb(239, 239, 239);
}
.align-right {
  text-align: right !important;
}
.free-tag {
  background-color: #e5232a;
  color: white;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 7px;
  vertical-align: middle;
}
.image-upload-instructions {
  font-size: 10px;
  font-weight: 500;
}
.color-item {
  padding: 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
}
.color-container {
  display: grid !important;
  grid-template-columns: auto auto auto auto !important;
}
::-webkit-scrollbar {
  width: 3px; /* Width for vertical scrollbar */
  height: 6px; /* Height for horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #ff272f; /* Scrollbar color */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Background of the scrollbar track */
  border-radius: #e6e6e6;
}
.MuiPaper-elevation {
  box-shadow: 8px 8px 48px rgba(0, 0, 0, 0.1) !important;
}

.MuiTypography-root {
  font-size: 0.9rem !important;
}
.side-nav-container .MuiListItemIcon-root {
  min-width: 40px;
}
.hide-menu-text {
  display: none;
}
.order-details-container {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}

.order-detail-item {
  flex: 1 1 50%;
  box-sizing: border-box;
}

.order-detail-item p {
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 16px;
  color: black;
}
.order-detail-item p span {
  color: rgba(0, 0, 0, 0.6);
}
.chat-text {
  font-size: 12px !important;
}
.chat-timestamp {
  font-size: 10px !important;
  margin: 0px;
}
